<template>
 <div class="memberRechargePay-view">
   <img src="../assets/images/dianfei_p.png">

   <div class="memberRechargePay-title">
     <label><b>全国电费-慢充200元-24小时内到账【已欠费的不能充值】</b></label>
     <div class="memberRechargePay-info">
       <span>￥186.00</span>
       <del>原价200.00</del>
       <span>销量:1265</span>
     </div>
   </div>

   <van-cell-group>
     <van-field v-model="value" label="充值账号" placeholder="*仅限填入充值账号" input-align="right"/>
     <van-field v-model="value" label="备注" placeholder="请填写备注留意" input-align="right"/>
   </van-cell-group>

 
   <div class="remark">
     <p>下单请准确填写用电户号并备注所在省份</p>
     <p class="remark-red">例如12345678;河南省;郑州市</p>
     <p class="remark-red">注意:电费已欠费的用户会充值失败，请勿提交!</p>
     <p class="remark-red">目前不支持省份:广东海南</p>
     <p>1.家用电和工用电都可以充，下单请准确填写用电户号和</p>
     <p>所在省份城市，例如12345678河南省郑州市;</p>
     <p>2.如因用电户号和所在省份填写错误,由用户自行承担</p>
     <p>请务必准确填写用电户号和所在省份;</p>
     <p>3.到账时间:正常情况12-24小时内到账，最迟72小时内到账</p>
     <p>(电费可能会以多次如50元/100元等分次到账，总值200元)可叠加充值</p>
     <p>4.充值成功后请登录(网上国网APP)进行查询; .</p>
     <p>目前能充值的省份:江西，安徽,北京，重庆,福建,甘肃,河北,黑龙
       江新疆,河南,湖北，湖南,江苏，冀北，吉林，辽宁，内蒙古，宁夏，青
       海,山东,上海，山西，四川，天津,西藏,浙江</p>
   </div>

   <div class="recharge-btn">
     <nut-row>
       <nut-col :span="16">
         <div class="flex-content payment">
           应付{{money||0}}元
         </div>
       </nut-col>
       <nut-col :span="8" v-on:click="pay()">
         <div class="flex-content flex-content-light flex-content-btn">
           支付
         </div>
       </nut-col>
     </nut-row>
   </div>
 </div>
</template>

<script>

export default {
  name: "MemberRechargePay",
  data() {
    return {
       money:0.00
    }
  },
}
</script>

<style scoped>
.memberRechargePay-view img{
  width: 100%;
}
.memberRechargePay-title{
  padding: 5px 10px;
  background: #FFFFFF;
}
.memberRechargePay-title label{
  font-size: 20px;
}
.memberRechargePay-info{
  margin: 8px 0;
}
.memberRechargePay-info del{
  margin-left: 15px;
  color: #cccccc;
}
.memberRechargePay-info span:first-child{
  color: #F40;
  font-size: 20px;
}
.memberRechargePay-info span:last-child{
  float: right;
  color: #a5a5a5;
  margin-top: 3px;
}
.remark{
  padding: 8px;
  background: #FFFFFF;
  margin-bottom: 50px;
}
.remark p{
  margin: 5px;
}
.remark-red{
  color: red;
  background: yellow;
  font-weight: 600;
}
.recharge-btn{
  background: #ffffff;
  border-top: 1px solid #cccccc;
  height: 60px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.payment{
  margin-left: 20px;
  color: #ff2a2a;
  font-size: 18px;
}
.flex-content-btn{
  background: #2BD67E;
  text-align: center;
  color: #ffffff;
}
.active{
  background: #c9ffd2;
}
</style>
